// ** Redux Imports
//import useJwt from '@src/auth/jwt/useJwt'
//const config = useJwt.jwtConfig
import { createSlice } from '@reduxjs/toolkit'
import { logout } from '../configs/apollo'
const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
      //state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      //state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      //localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      //localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    },
    handleLogout: state => {
      state.userData = {}
      console.log('logout')
      //localStorage.removeItem('realm-web:app(app-base-foway):deviceId')
      //realm-web:app(app-base-foway):deviceId
     // state[config.storageTokenKeyName] = null
     // state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      logout()
      //localStorage.removeItem(config.storageTokenKeyName)
     // localStorage.removeItem(config.storageRefreshTokenKeyName)
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
