// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const UserList = lazy(() => import('../../views/apps/user/list'))

const AppRoutes = [
  {
    element: <UserList />,
    path: '/apps/user/list'
  }
]

export default AppRoutes
